import Web3 from "web3";
import Web3Modal from "web3modal";
import OGLizardBridge from "../config/OGLizardBridge.json";

let { web3 } = window
export const getCoinbase = async () => {
    //  Get Accounts
    const accounts = await window.web3Object.eth.getAccounts();
    return accounts.length > 0 ? accounts[0] : ""
}

// Function to get Contract
export const getContract = async (key) => {
    let ABI, address
    if (key === "OGLizardBridge") {
        ABI = OGLizardBridge.abi
        address = OGLizardBridge.contractAddress
    } 
        
    let contract = new window.web3Object.eth.Contract(ABI, address, {from: await getCoinbase()})
    return contract;
}

class OGLizardBridgeContract {
    async getTransferredIds(acc) {
        let contract = await getContract("OGLizardBridge")
        return (await contract?.methods.getTransferredIds(acc).call())
    }

    async getMigratedIds(acc) {
        let contract = await getContract("OGLizardBridge")
        return (await contract?.methods.getMigratedIds(acc).call())
    }

    async getTotalCount() {
        let contract = await getContract("OGLizardBridge")
        return (await contract?.methods.totalSupply().call())
    }

    async migrate(_oldId, _newId, _leaf, _merkleProof) {
        let contract = await getContract("OGLizardBridge") 
        return (await contract?.methods.migrate(_oldId, _newId, _leaf, _merkleProof).send({
            from: await getCoinbase(),
        }))
    }
}
const web3E = new Web3(new Web3.providers.WebsocketProvider('wss://mainnet.infura.io/ws/v3/a6f8437631884f188a87dd2f9c8da2a8')) 
window.contractE = new web3E.eth.Contract(OGLizardBridge.abi, OGLizardBridge.contractAddress)



window.OGLizardBridge = new OGLizardBridgeContract()