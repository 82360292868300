const { utils } = require('ethers')
//Load Libraries required for MerkleTree
const { MerkleTree } = require('merkletreejs')
const keccak256 = require('keccak256')
const ids = require('../../ids.json')

  //This function determines if the oldID is Valid, and then returns the correct newID.
const verifyMerkleOSID = (OSID) => {
    var index = 0
    var found
    var entry

    //Loop through whitelist to find if Address is in whitelist
    for (index = 0; index < ids.length; ++index) {
      entry = ids[index]
      if (entry.OSID == OSID) {
        found = entry.NEWID
        break
      } else {
        found = 0
      }
    }

    return found
  }

export const transferredNFTs = async(owner) => {
    const ids = await window.OGLizardBridge.getTransferredIds(owner)
    let items = []
    const migratedIds = await window.OGLizardBridge.getMigratedIds(owner)

    for (let id of ids) {
        if (!migratedIds.includes(id)) {
            const newID = verifyMerkleOSID(id)
            const imagePath = `/images/Genesis_Images/${newID}.png`
            items.push({oldID: id, newID: verifyMerkleOSID(id), image: imagePath})
        }
    }

    return items;
}

export const migratedIDs = async(owner) => {
    const ids = await window.OGLizardBridge.getMigratedIds(owner)
    let items = []
    for (let id of ids) {
        const newID = verifyMerkleOSID(id)
        const imagePath = `/images/Genesis_Images/${newID}.png`
        items.push({oldID: id, newID: verifyMerkleOSID(id), image: imagePath})
    }

    return items;
}

export const getTotalCount = async() => {
  const cnt = await window.OGLizardBridge.getTotalCount()
  return cnt;
}

//This function calls Verify to determine how many Mints are allowed.   //Then creates a merkle tree and passes back the Proof.

export const createLeaf = (leafOld, leafNew) => {

    const leafNodes = utils.solidityKeccak256(["uint256", "uint256"], [leafOld, leafNew]);

    if (leafNodes.length > 0) {
      return leafNodes
    } else {
      console.log('No Leaf Calculated')
      return 0
    }
  }

export const createProof = (leafOld, leafNew) => {

    //Creates all the LeafNodes
    const leafNodes = ids.map((x) =>
      utils.solidityKeccak256(['uint256', 'uint256'], [x.OSID, x.NEWID]),
    )

    //Create MerkleTree and Root
    const merkleTree = new MerkleTree(leafNodes, keccak256, {
      sortPairs: true,
    })

    const rootHash = merkleTree.getRoot()

    //Create Leaf
    const Leaf = utils.solidityKeccak256(
      ['uint256', 'uint256'],
      [leafOld, leafNew],
    )

    const hexProof = merkleTree.getHexProof(Leaf)
    if (hexProof.length > 0) {
      return hexProof
    } else {
      console.log('No Hex Proof Found')
      return 0
    }
  }
