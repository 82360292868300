import { useDispatch, useSelector } from 'react-redux'
import { connectRequest, connectSuccess, connectFailed, updateAccountRequest } from '../redux/blockchain/blockchainActions'
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import web3Modal from "../functions/web3Modal";
import {
    StyledButton,
    ResponsiveWrapper,
  } from '../styles/globalStyles'
import * as s from '../styles/globalStyles'

export default function Home() {
    const navigate = useNavigate();
    const blockchain = useSelector((state) => state.blockchain)
    const CONFIG = useSelector((state) => state.config);
    const dispatch = useDispatch()


    const connectWallet = async () => {
        dispatch(connectRequest());
        const configResponse = await fetch("/config/config.json", {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        });
        const { ethereum } = window;
        if (ethereum) {
            const CONFIG = await configResponse.json();
            await web3Modal.clearCachedProvider()
            try {
                const provider = await web3Modal.connect();
                const web3Object = new Web3(provider);
                window.web3Object = web3Object;
                const accounts = await web3Object.eth.getAccounts();
                const chainId = await web3Object.eth.net.getId();
                if (chainId == CONFIG.NETWORK.ID) {
                dispatch(
                    connectSuccess({
                    account: accounts[0],
                    })
                );
                navigate('/Bridge')
                // Add listeners start
                provider.on("accountsChanged", (accounts) => {
                    dispatch(updateAccountRequest(accounts[0]));
                    console.log(accounts[0])
                    navigate('/')
                });
                provider.on("chainChanged", () => {
                    window.location.reload();
                });
                // Add listeners end
                } else {
                dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
                }
            } catch (err) {
                dispatch(connectFailed("Something went wrong."));
            }
        } else {
            dispatch(connectFailed("Install Metamask."));
        }
    };

    return (
        <>
            <s.Container flex={1} ai={'center'}>
                <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
                    <s.MintContainer flex={2} jc={'center'} ai={'center'}>
                        <s.TextTitle
                            style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            color: '#ECCD58',
                            fontFamily: 'Kanit',
                            '-webkit-text-stroke': '4px black',
                            marginTop: '200px'
                            }}
                        >
                            GENESIS BRIDGE
                        </s.TextTitle>
                        <s.TextDescription
                            style={{
                                color: 'white',
                                marginTop: '50px'
                            }}
                        >
                         Connect to the {CONFIG.NETWORK.NAME} network
                        </s.TextDescription>
                        <s.SpacerSmall />

                        {/*========================CONNECT TO WEB3 ===============================*/}
                        <StyledButton
                        style={{padding: "20px 60px", fontSize: "40px"}}
                        onClick={(e) => {
                        connectWallet(e)  
                        }}
                        >
                        CONNECT
                        </StyledButton>
                        {blockchain.errorMsg !== '' ? (
                        <>
                            <s.SpacerSmall />
                            <s.TextDescription
                            style={{
                                textAlign: 'center',
                                color: 'white',
                            }}
                            >
                            {blockchain.errorMsg}
                            </s.TextDescription>
                        </>
                        ) : null}
                    </s.MintContainer>
                </ResponsiveWrapper>
            </s.Container>
        </>
    )
}