import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Bridge from './page/Bridge';
import Migrated from './page/Migrated';
import Home from './page/Home';
import Header from './page/Header';
//import MerklePage from './page/MerklePage';
//import MintPageWL from './page/MintPageWL';

const App = () => {
//  return <MintPageOG></MintPageOG>;
  return (
    <>
      <Router>
        <Header/>
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route path="/Bridge" element={<Bridge />}/>
          <Route path="/Migrated" element={<Migrated />}/>
        </Routes>
      </Router>
    </>
  )
};
export default App;
