import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { migratedIDs, getTotalCount } from '../functions/utility'
import {
    StyledButton,
    StyledRoundButton,
    ResponsiveWrapper,
} from '../styles/globalStyles'
import * as s from '../styles/globalStyles'


//Load JSON File with Old and new ID's

const truncate = (input, len) =>
    input.length > len ? `${input.substring(0, len)}...` : input

const Migrated = () => {
    const navigate = useNavigate();
    const blockchain = useSelector((state) => state.blockchain)

    const [itemNumber, setItemNumber] = useState(1);
    const [migratedNFTs, setMigratedNFTs] = useState([])
    const [totalCnt, setTotalCnt] = useState(0)
    const [description, setDescription] = useState('Loading...');

    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: '',
        OSSTOREFRONT_ADDRESS: '',
        PROXY_ADDRESS: '',
        SCAN_LINK: '',
        NETWORK: {
            NAME: '',
            SYMBOL: '',
            ID: 0,
        },
        NFT_NAME: '',
        SYMBOL: '',
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: '',
        MARKETPLACE_LINK: '',
        SHOW_BACKGROUND: false,
        MINT_STATUS: '',
    })

    const downIndex = () => {
        if (itemNumber > 1) {
            setItemNumber(itemNumber - 1);
        }
    }

    const upIndex = () => {
        if (itemNumber < migratedNFTs.length) {
            setItemNumber(itemNumber + 1);
        }
    }

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
        const config = await configResponse.json()
        SET_CONFIG(config)
    }

    const loadMigratedNFTs = async () => {
        const nfts = await migratedIDs(blockchain.account)
        setMigratedNFTs(nfts)
        if(nfts.length == 0) {
            setDescription('You currently have no genesis ethilzards in the new contract. Please go to Bridge page and migrate your eth lizard.')
        }
    }

    const totalMigratedCnt = async () => {
        const cnt = await getTotalCount();
        setTotalCnt(cnt)
    }

    useEffect(async () => {
        if (blockchain.account) {
            await getConfig()
            await loadMigratedNFTs()
            await totalMigratedCnt()
        } else {
            navigate('/')
        }

    }, [])

    return (
        <s.Screen>
            {/* BACKGROUND IMAGE */}
            <s.Container
                flex={1}
                ai={'center'}
            >
                {/* TOP ROW CONTAINER */}

                <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
                    <s.MintContainer flex={2} jc={'center'} ai={'flex-start'}>
                        <s.Container jc={'center'} ai={'center'}>
                            {/* HEADING IF SALE OPEN OR ENDED*/}
                            <s.TextTitle
                                style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    color: '#ECCD58',
                                    fontFamily: 'Kanit',
                                    '-webkit-text-stroke': '5px black',
                                }}
                            >
                                {' '}
                                MIGRATED LIZARDS{' '}
                            </s.TextTitle>

                            {
                                migratedNFTs[0] ? (
                                    <>
                                        {' '}
                                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                            <s.SpacerMedium />
                                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                                <StyledRoundButton
                                                    style={{ lineHeight: 0.4 }}
                                                    onClick={downIndex}
                                                >
                                                    &lt;
                                                </StyledRoundButton>
                                            </s.Container>
                                            <s.ImageDiv>
                                                <s.ImageShow src={migratedNFTs[itemNumber - 1].image} />
                                            </s.ImageDiv>
                                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                                <StyledRoundButton
                                                    style={{ lineHeight: 0.4 }}
                                                    onClick={upIndex}
                                                >
                                                    &gt;
                                                </StyledRoundButton>
                                            </s.Container>
                                            <s.SpacerMedium />
                                        </s.Container>
                                        <s.TextTitle
                                            style={{
                                                textAlign: "center",
                                                fontSize: 24,
                                                fontWeight: "bold",
                                                color: "#ECCD58",
                                                fontFamily: "DM Sans",
                                            }}
                                        >
                                            {itemNumber}/{migratedNFTs.length}: Lizard #{migratedNFTs[itemNumber - 1].newID}
                                        </s.TextTitle>

                                        <s.SpacerMedium />

                                        <s.Container ai={'center'} jc={'center'} fd={'row'}>
                                            <StyledButton
                                            >
                                                <a 
                                            
                                                    href={`https://opensea.io/assets/${CONFIG.MIGRATED_ADDRESS}/${migratedNFTs[itemNumber - 1].newID}`} 
                                                    target={'_blank'}
                                                    style={{color: "#a78535"}}
                                                >
                                                    View on Opensea
                                                </a>
                                            </StyledButton>
                                        </s.Container>
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        <s.TextTitle
                                            style={{
                                            textAlign: 'center',
                                            color: '#cae340',
                                            fontSize: 33,
                                            fontFamily: 'DM Sans',
                                            maxWidth: '50%',
                                            marginBottom: '150px',
                                            }}
                                        >
                                            {' '}
                                            {description}
                                        </s.TextTitle>{' '}
                                    </>
                                )
                            }
                        </s.Container>
                    </s.MintContainer>
                    <s.Container ai={'flex-end'} flex={1}>
                        <div style={{ margin: "30px" }}>
                            <s.TextTitle
                                style={{
                                    textAlign: 'center',
                                    fontSize: 24,
                                    fontWeight: 'bold',
                                    color: 'white',
                                    fontFamily: 'DM Sans',
                                }}
                            >
                                Total Migrated
                            </s.TextTitle>
                            <s.TextMinted style={{ color: 'white' }}>
                                {totalCnt} / {CONFIG.MAX_SUPPLY}
                            </s.TextMinted>
                            <s.SpacerSmall />
                            {CONFIG.MINT_STATUS === 'ACTIVE' && (
                                <s.TextTitle
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 18,
                                        fontWeight: 700,
                                        fontFamily: 'DM Sans',
                                        color: '#7ACD60',
                                    }}
                                >
                                    Migration Bridge is Open!
                                </s.TextTitle>
                            )}
                            {CONFIG.MINT_STATUS === 'PAUSED' && (
                                <s.TextTitle
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 18,
                                        fontWeight: 700,
                                        fontFamily: 'DM Sans',
                                        color: '#FF8700',
                                    }}
                                >
                                    Bridge is currently not active
                                </s.TextTitle>
                            )}
                        </div>
                    </s.Container>
                </ResponsiveWrapper>

                {/* BOTTOM TEXT DISCLAIMER AND MESSAGES */}
                <s.Container jc={'center'} ai={'center'} style={{ width: '70%' }}>
                    <s.TextDisclaimer
                        style={{
                            textAlign: 'center',
                            color: 'white',
                        }}
                    >
                        Please make sure you are connected to the right network (
                        {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
                        Once you make the purchase, you cannot undo this action.
                    </s.TextDisclaimer>
                </s.Container>
            </s.Container>
        </s.Screen>
    )
}

export default Migrated
