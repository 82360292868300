import styled, { css } from 'styled-components';

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: transparent;
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : 'column')};
  justify-content: ${({ jc }) => (jc ? jc : 'flex-start')};
  align-items: ${({ ai }) => (ai ? ai : 'flex-start')};
  background-color: ${({ test }) => (test ? 'pink' : 'none')};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  background-size: cover;
  background-position: center;
`;

export const MintContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : 'column')};
  justify-content: ${({ jc }) => (jc ? jc : 'flex-start')};
  align-items: ${({ ai }) => (ai ? ai : 'flex-start')};
  background-color: ${({ test }) => (test ? 'pink' : 'none')};
  max-width: 50%;
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 65px;
  @media (max-width: 1024px) {
    font-size: 40px;
  };
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const StyledInput = styled.input`
display: flex;
flex-direction: row;
width: 100%;
max-width: 1000px;
margin: 0 auto;
border-radius: 2px;
padding: 1.4rem 2rem 1.6rem;
background: $input-background;
&:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  height: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-position: 0% 0%;
  background: linear-gradient(to right, #B294FF, #57E6E6, #FEFFB8, #57E6E6, #B294FF, #57E6E6);
  background-size: 500% auto;
  animation: gradient 3s linear infinite;
  `;

export const StyledLink = styled.a`
  color: var(--accent-text);
  text-decoration: none;
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  left: 233px;
  top: 456px;
  @media (max-width: 768px) {
    width: 148.66px;
    font-size: 20px;
    height: 48.66px;
  }
  padding: 10px 50px;
  border-radius: 49.3301px;
  font-weight: 700;
  font-family: Kanit;
  font-size: 24px;
  color: #a78535;
  cursor: pointer;
  background: #ffffff;
  ${(props) =>
    props.disabled
      ? css`
          cursor: not-allowed;
          color: #a9a9a9;
          background: gray;
          border: none;
        `
      : css`
          cursor: hover;
        `};
`;

export const StyledRoundButton = styled.button`
  width: 78.66px;
  height: 78.66px;
  left: 511.98px;
  top: 311.21px;
  @media (max-width: 768px) {
    width: 48.66px;
    font-size: 30px;
    height: 48.66px;
  }
  background: #161337;
  border-radius: 100%;
  border: none;
  padding: 10px;
  font-weight: bold;
  font-size: 50px;
  font-family: DM Sans;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.disabled
      ? css`
          cursor: not-allowed;
          color: #a9a9a9;
          background: gray;
          border: none;
        `
      : css`
          cursor: hover;
        `};
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const TextAmount = styled.p`
  color: var(--accent-text);
  font-size: 140px;
  line-height: 150px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: DM Sans;
  min-width: 150px;
  @media (max-width: 768px) {
    min-width: 48.66px;
    font-size: 90px;
    margin-bottom: 5px;
  }
`;

export const TextMinted = styled.p`
  color: var(--accent-text);
  font-size: 40px;
  background: #000;
  padding: 10px;
  border-radius: 13.5px;
  text-align: center;
  font-family: DM Sans;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const TextDisclaimer = styled.p`
  color: var(--primary-text);
  font-size: 16px;
  line-height: 1.6;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const TextNav = styled.div`
  color: white;
  font-size: 32px;
  margin-left: 20px;
  font-family: Sniglet;
  @media (max-width: 1024px) {
    font-size: 20px;
  };
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 4px solid;
  border-color: #ECCD58;
  border-radius: 5px;
  padding: 10px;
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ImageShow = styled.img`
  height: 340px;
  margin: auto;
`;
