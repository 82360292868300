import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as s from '../styles/globalStyles';
import { configDataLoad } from '../redux/config/configActions'
import LIZ from "../styles/images/liz-nav.png" 
import ETHER from "../styles/images/etherscan-nav.png" 
import OS from "../styles/images/os-nav.png" 

export default function Header() {
    const dispatch = useDispatch()
    const [copyFlag, setCopyFlag] = useState(false)
    const CONFIG = useSelector((state) => state.config);
    const account = useSelector((state) => state.blockchain.account)

    useEffect(async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          })
          const config = await configResponse.json()
          dispatch(configDataLoad(config))
    }, []);

    useEffect(()=> {
        if (copyFlag) {
            setTimeout(() => {setCopyFlag(false)}, 5000)
        }
    }, [copyFlag])

    return (
        <s.Container jc={'space-between'} ai={'center'} fd={'row'}>
            {/* ETH LIZARD LOGO AND LINK AT TOP */}
            <s.Container ai={'center'} fd={'row'}>
            <a
                href={'https://ethlizards.io'}
                target={'_blank'}
                style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                textDecoration: 'none',
                }}
            >
                <img src={LIZ} />
                <s.TextNav>EthLizards</s.TextNav>
            </a>
            </s.Container>
            <CopyToClipboard text={CONFIG.CONTRACT_ADDRESS} onCopy={()=>setCopyFlag(true)}>
                <s.TextNav>
                    Contract Address {CONFIG.CONTRACT_ADDRESS}
                    {copyFlag ? <span style={{color: 'red', fontSize: '18px'}}> Copied</span> : null}
                </s.TextNav>
            </CopyToClipboard>
            <s.Container fd={'row'} style={{fontSize: '30px'}}>
                {
                    account ? (
                        <>
                            <NavLink to="/Bridge" style={{color: "white"}}>Bridge</NavLink>
                            <NavLink to="/Migrated" style={{color: "white", marginLeft: "20px"}}>Migrated</NavLink>
                        </>
                    ) : (
                        <></>
                    )
                }
            </s.Container>
            {/* ETHERSCAN AND OS LOGO AND LINK AT TOP */}
            <s.Container fd={'row'}>
            <a href={CONFIG.SCAN_LINK} target={'_blank'}>
                <img src={ETHER} />
            </a>
            <a href={CONFIG.MARKETPLACE_LINK} target={'_blank'}>
                <img
                src={OS}
                style={{ marginLeft: '20px' }}
                />
            </a>
            </s.Container>
        </s.Container>
    )
}