const initialState = {
    CONTRACT_ADDRESS: '',
    OSSTOREFRONT_ADDRESS: '',
    PROXY_ADDRESS: '',
    SCAN_LINK: '',
    NETWORK: {
      NAME: '',
      SYMBOL: '',
      ID: 0,
    },
    NFT_NAME: '',
    SYMBOL: '',
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: '',
    MARKETPLACE_LINK: '',
    SHOW_BACKGROUND: false,
    MINT_STATUS: '',
  };
  
  const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CONFIG_DATA_LOAD":
            return {
                ...state,
                ...action.payload
            }
      default:
        return state;
    }
  };
  
  export default configReducer;
  