import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { migratedIDs, transferredNFTs, createLeaf, createProof, getTotalCount } from '../functions/utility'
import {
  StyledButton,
  StyledRoundButton,
  ResponsiveWrapper,
} from '../styles/globalStyles'
import * as s from '../styles/globalStyles'


//Load JSON File with Old and new ID's

const Bridge = () => {
  const navigate = useNavigate();
  const blockchain = useSelector((state) => state.blockchain)
  const [claimingNft, setClaimingNft] = useState(false)
  const [feedback, setFeedback] = useState(
    `Click Migrate to convert your Lizard to ERC721.`,
  )

  const [itemNumber, setItemNumber] = useState(1);
  const [nfts, setNfts] = useState([]);
  const [reload, setReload] = useState(true);
  const [description, setDescription] = useState('Loading...');
  const [migratedCnt, setMigratedCnt] = useState(0)
  const [allowedMint, setAllowedMint] = useState(10)
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: '',
    OSSTOREFRONT_ADDRESS: '',
    PROXY_ADDRESS: '',
    SCAN_LINK: '',
    NETWORK: {
      NAME: '',
      SYMBOL: '',
      ID: 0,
    },
    NFT_NAME: '',
    SYMBOL: '',
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: '',
    MARKETPLACE_LINK: '',
    SHOW_BACKGROUND: false,
    MINT_STATUS: '',
  })

  const downIndex = () => {
    if (itemNumber > 1) {
      setItemNumber(itemNumber - 1);
    } 
  }

  const upIndex = () => {
    if (itemNumber < nfts.length) {
      setItemNumber(itemNumber + 1);
    }  
  }

  window.contractE.events.ReceivedFromOS().on("data",  function(event) {
    setReload(true)
  })

  const Migrate = async (_oldId, _newId, _leaf, _merkleProof) => {

    setFeedback(`Migrating your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    try {
      const tx = await window.OGLizardBridge.migrate(_oldId, _newId, _leaf, _merkleProof)
      setFeedback(
        `WOW, the ${CONFIG.NFT_NAME} has been Migrated successfully. You can view your migrated lizards under the 'migrated' tab or on opensea.`
      );
      init()
      setClaimingNft(false);
    } catch(err) {
      console.log(err);
      setFeedback('Sorry, something went wrong please try again later.');
      setClaimingNft(false);
    }
  };

  
  const getConfig = async () => {
    const configResponse = await fetch('/config/config.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    const config = await configResponse.json()
    SET_CONFIG(config)
  }

  const loadMigratedCnt = async () => {
    const cnt = await getTotalCount()
    setMigratedCnt(cnt)
  }

  const loadTransferredNFTs = async () => {
    const items = await transferredNFTs(blockchain.account)
    setNfts(items)
    if(nfts.length == 0) {
      setDescription('You currently have no genesis ethilzards in the bridge to migrate. Please go to opensea and transfer your eth lizard to the contract address above.')
    }
  }

  const init = async() => {
    if (blockchain.account) {
      await getConfig()
      await loadMigratedCnt()
      await loadTransferredNFTs()
    } else {
      navigate('/')
    }
    setReload(false)
  }

  useEffect(() => {
    if (reload) {
      init()
    }
  }, [reload])

  return (
    <s.Screen>
      {/* BACKGROUND IMAGE */}
      <s.Container
        flex={1}
        ai={'center'}
      >
        {/* TOP ROW CONTAINER */}

        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.MintContainer flex={2} jc={'center'} ai={'flex-start'}>
            <s.Container jc={'center'} ai={'center'}>
              {/* HEADING IF SALE OPEN OR ENDED*/}
              <s.TextTitle
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#ECCD58',
                  fontFamily: 'Kanit',
                  '-webkit-text-stroke': '5px black',
                }}
              >
                {' '}
                GENESIS BRIDGE
              </s.TextTitle>

              {nfts.length == 0 ? (
                // ============================THIS IS WHAT DISPLAYS WHEN TOTAL SUPPLY >= MAX SUPPLY ==============================//
                <>
                  {' '}
                  <s.TextTitle
                    style={{
                      textAlign: 'center',
                      color: '#cae340',
                      fontSize: 33,
                      fontFamily: 'DM Sans',
                      maxWidth: '50%',
                      marginBottom: '150px',
                    }}
                  >
                    {' '}
                    {description}
                  </s.TextTitle>{' '}
                </>
              ) : (
                // ============================THIS IS WHAT DISPLAYS WHEN BRIDGE IS STILL OPEN===============================//
                <>
                  {' '}
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 24,
                      fontWeight: "bold",
                      color: "white",
                      fontFamily: "DM Sans",
                    }}
                  >
                    Lizards ready to migrate
                  </s.TextTitle>
                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={downIndex}
                      >
                        &lt;
                      </StyledRoundButton>
                    </s.Container>
                    <s.ImageDiv> 
                      {
                        nfts[0] && (
                          <s.ImageShow src={nfts[itemNumber - 1].image} />
                        )
                      }
                    </s.ImageDiv>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={upIndex}
                      >
                        &gt;
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerMedium />
                  </s.Container>
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 24,
                      fontWeight: "bold",
                      color: "#ECCD58",
                      fontFamily: "DM Sans",
                    }}
                  >
                    {itemNumber}/{nfts.length}: Lizard #{nfts[itemNumber - 1].newID}
                  </s.TextTitle>

                  <s.SpacerMedium />

                  <s.Container ai={'center'} jc={'center'} fd={'row'}>
                    <StyledButton
                      disabled={
                        claimingNft
                          ? 1
                          : 0 ||
                          CONFIG.MINT_STATUS !== 'ACTIVE'
                      }
                      onClick={(e) => {

                        var merkleLeaf
                        var merkleProof

                        if (nfts[itemNumber - 1].newID == 0) {
                          alert('No ID Found');
                          return;
                        }

                        merkleLeaf = createLeaf(nfts[itemNumber - 1].oldID, nfts[itemNumber - 1].newID);
                        merkleProof = createProof(nfts[itemNumber - 1].oldID, nfts[itemNumber - 1].newID);

                        Migrate(nfts[itemNumber - 1].oldID, nfts[itemNumber - 1].newID, merkleLeaf, merkleProof);

                      }}
                    >
                      {claimingNft ? 'BUSY' : 'MIGRATE'}
                    </StyledButton>
                  </s.Container>

                  <s.SpacerSmall />

                  <s.TextTitle
                    style={{
                      textAlign: 'center',
                      fontSize: 14,
                      fontWeight: 400,
                      color: 'white',
                      fontFamily: 'DM Sans',
                    }}
                  >
                    {allowedMint > 0 ? (
                      <>
                        {feedback}
                        {/* <div>
                          {'Go to Opensea and Transfer your Genesis Lizard to this Bridge Contract'}
                        </div> */}
                        {/* <div>
                          {'Genesis Lizards in the Bridge will appear here.'}
                        </div> */}
                      </>
                    ) : (
                      <div>{feedback}</div>
                    )}
                  </s.TextTitle>
                </>
              )}
            </s.Container>
          </s.MintContainer>
          <s.Container ai={'flex-end'} flex={1}>
            <div style={{margin: "30px"}}>
              <s.TextTitle
                style={{
                  textAlign: 'center',
                  fontSize: 24,
                  fontWeight: 'bold',
                  color: 'white',
                  fontFamily: 'DM Sans',
                }}
              >
                Total Migrated
              </s.TextTitle>
              <s.TextMinted style={{ color: 'white' }}>
                {migratedCnt} / {CONFIG.MAX_SUPPLY}
              </s.TextMinted>
              <s.SpacerSmall />
              {CONFIG.MINT_STATUS === 'ACTIVE' && (
                <s.TextTitle
                  style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    fontFamily: 'DM Sans',
                    color: '#7ACD60',
                  }}
                >
                  Migration Bridge is Open!
                </s.TextTitle>
              )}
              {CONFIG.MINT_STATUS === 'PAUSED' && (
                <s.TextTitle
                  style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    fontFamily: 'DM Sans',
                    color: '#FF8700',
                  }}
                >
                  Bridge is currently not active
                </s.TextTitle>
              )}
            </div>
          </s.Container>
        </ResponsiveWrapper>

        {/* BOTTOM TEXT DISCLAIMER AND MESSAGES */}
        <s.Container jc={'center'} ai={'center'} style={{ width: '70%' }}>
          <s.TextDisclaimer
            style={{
              textAlign: 'center',
              color: 'white',
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDisclaimer>
        </s.Container>
      </s.Container>
    </s.Screen>
  )
}

export default Bridge
